// return in the format of Wednesday (MM/DD)
const formatWeekDate = (date) => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  const weekday = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(parsedDate);
  const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(parsedDate);
  const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(parsedDate);
  return `${weekday} (${month}/${day})`;
};

// Return a date in the format of YYYY-MM-DD
const formatDate = (date) => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return parsedDate.toISOString().split('T')[0];
};

// Helper function to calculate the nth weekday of a month
const getNthWeekdayOfMonth = (nth, weekday, month, year) => {
  const firstDayOfMonth = new Date(year, month, 1);
  const firstWeekday = firstDayOfMonth.getDay();
  const dayOffset = (weekday - firstWeekday + 7) % 7;
  return 1 + dayOffset + (nth - 1) * 7;
};

// Helper function to calculate the last weekday of a month
const getLastWeekdayOfMonth = (weekday, month, year) => {
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const lastWeekday = lastDayOfMonth.getDay();
  const dayOffset = (lastWeekday - weekday + 7) % 7;
  return lastDayOfMonth.getDate() - dayOffset;
};

const isObservedHoliday = (date, month, year, holidayDate, holidayMonth) => {
  const holiday = new Date(year, holidayMonth, holidayDate);
  const holidayDay = holiday.getDay();

  if (holidayDay === 6) {
    // Holiday is on a Saturday, observe it on Friday
    return date === holidayDate - 1 && month === holidayMonth;
  } if (holidayDay === 0) {
    // Holiday is on a Sunday, observe it on Monday
    return date === holidayDate + 1 && month === holidayMonth;
  }
  // Regular holiday
  return date === holidayDate && month === holidayMonth;
};

const isHoliday = (inputDate) => {
  if (!(inputDate instanceof Date)) throw new Error('Invalid date input');
  const date = inputDate.getDate();
  const month = inputDate.getMonth();
  const day = inputDate.getDay();
  const year = inputDate.getFullYear();

  const thanksgiving = getNthWeekdayOfMonth(4, 4, 10, year); // 4th Thursday in November
  // check all the weekends and holidays
  return (day === 0 || day === 6) // weekends
    // New Year's Day - fixed
    || isObservedHoliday(date, month, year, 1, 0)
    // Martin Luther King Jr. Day - 3rd Monday in January
    || (date === getNthWeekdayOfMonth(3, 1, 0, year) && month === 0)
    // Patriot's Day - 3rd Monday in April
    || (date === getNthWeekdayOfMonth(3, 1, 3, year) && month === 3)
    // Memorial Day - Last Monday in May
    || (date === getLastWeekdayOfMonth(1, 4, year) && month === 4)
    // Juneteenth - fixed
    || isObservedHoliday(date, month, year, 19, 5)
    // Independence Day - fixed
    || isObservedHoliday(date, month, year, 4, 6)
    // Labor Day - 1st Monday in September
    || (date === getNthWeekdayOfMonth(1, 1, 8, year) && month === 8)
    // Indigenous Peoples' Day - 2nd Monday in October
    || (date === getNthWeekdayOfMonth(2, 1, 9, year) && month === 9)
    // Veterans Day - fixed
    || isObservedHoliday(date, month, year, 11, 10)
    || (date === 25 && month === 10 && year === 2024) // Thanksgiving Monday only for 2024
    || (date === 26 && month === 10 && year === 2024) // Thanksgiving Tuesday only for 2024
    || (date === 27 && month === 10 && year === 2024) // Thanksgiving Wednesday only for 2024
    // Thanksgiving - getNthWeekdayOfMonth(4, 4, 10, year)
    || (date === thanksgiving && month === 10)
    // day after Thanksgiving
    || (date === thanksgiving + 1 && month === 10)
    // check if Christmas Eve is on a weekend
    || isObservedHoliday(date, month, year, 24, 11)
    // Christmas
    || (date > 23 && month === 11);
};

const getShipDateByDays = (days, from = null) => {
  let daysToAdd = parseInt(days, 10);

  let current = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
  if (from) {
    if (from instanceof Date) {
      current = new Date(from);
    } else {
      throw new Error('Invalid date input');
    }
  }

  let isStartOnHoliday = false;
  // if the order is placed on a holiday, keep adding days until it's not a holiday
  while (isHoliday(current)) {
    current.setDate(current.getDate() + 1);
    isStartOnHoliday = true;
  }

  // if ordered after 5pm on a weekday, add one more day
  if (current.getHours() >= 17 && !isStartOnHoliday) {
    daysToAdd += 1;
  }

  let daysAdded = 0;
  while (daysAdded < daysToAdd) {
    current.setDate(current.getDate() + 1);
    if (!isHoliday(current)) {
      daysAdded += 1;
    }
  }
  return current;
};

const calculateBusinessDays = (futureDate) => {
  const today = new Date();
  const endDate = new Date(futureDate);

  // Ensure the date is in the future
  if (endDate <= today) {
    return 0;
  }

  let businessDays = 0;
  const currentDate = new Date(today);

  // Iterate through days until reaching the future date
  while (currentDate < endDate) {
    if (!isHoliday(currentDate)) {
      businessDays += 1;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return businessDays;
};

const formatAddress = (address = {}) => {
  const {
    name, company, street1, street2, city, state, zip, country,
  } = address;

  if (!street1) return 'No address provided';

  // Compose the address parts
  const shipName = company ? `Attn: ${name}` : name;
  const line1 = street1;
  const line2 = street2 ? `${street2}` : '';
  const line3 = `${city}, ${state} ${zip}`;
  const line4 = country ? `${country}` : '';

  // Return the formatted address
  return [company, shipName, line1, line2, line3, line4].filter(Boolean).join('\n'); // Filter out empty lines
};

module.exports = {
  getShipDateByDays,
  formatWeekDate,
  formatDate,
  calculateBusinessDays,
  formatAddress,
};
