import {
  Alert,
  Button,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import { formatAddress } from '@parallel-fluidics/shipping';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AddressInputs from './addressInputs';

export default function AddressDisplay({
  address = {
    name: '',
    company: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  backupAddress = null,
  onEdit = null,
}) {
  const [visible, setVisible] = useState(false);

  return (
    <SpaceBetween size="xs">
      {!address?.street1 && backupAddress ? (
        <Alert
          statusIconAriaLabel="Error"
          type="error"
          header={onEdit ? 'Please re-enter the address using the "Edit Address" button below.' : 'Displaying backup address:'}
        >
          <div style={{ whiteSpace: 'pre-line' }}>
            {backupAddress}
          </div>
        </Alert>
      ) : (
        <div style={{ whiteSpace: 'pre-line' }}>
          {formatAddress(address)}
        </div>
      )}
      {onEdit && (
        <Button type="button" formAction="none" onClick={() => setVisible(true)}>
          Edit Address
        </Button>
      )}

      {visible && (
        <Modal
          onDismiss={() => setVisible(false)}
          visible={visible}
          header="Edit Address"
        >
          <AddressInputs
            defaultAddress={address}
            onSubmit={(newAddress) => {
              onEdit(newAddress);
              setVisible(false);
            }}
          />
        </Modal>
      )}
    </SpaceBetween>
  );
}

AddressDisplay.propTypes = {
  address: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  backupAddress: PropTypes.string,
};
