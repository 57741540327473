import React from 'react';

import { usePackage } from './packageProvider';

export default function PDFViewer() {
  const { packageData } = usePackage();
  return (
    <iframe
      src={packageData.packingSlipUrl}
      width="100%"
      height="100%"
      style={{ border: 'none' }}
      title="PDF Viewer"
    />
  );
}
