import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://baa1225277a85662569feb57f4b7ab94@o507646.ingest.us.sentry.io/4508653868220416',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      createBrowserRouter,
      useLocation,
      useNavigate,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],

  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0, // Capture 100% of the transactions

  // Session Replay
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: process.env.REACT_APP_ENV === 'local' ? 0 : 1.0,
  replaysOnErrorSampleRate: 1.0,

  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV,
});
