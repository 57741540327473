import { formatAddress } from '@parallel-fluidics/shipping';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  Tspan,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

Font.register({
  family: 'Inter',
  fonts: [
    { src: '/fonts/Inter/TTF/Inter-Regular.ttf' },
    { src: '/fonts/Inter/TTF/Inter-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/Inter/TTF/Inter-Medium.ttf', fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 48,
    fontSize: 9,
    lineHeight: 1.5,
    fontFamily: 'Inter',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 2,
    borderBottomColor: '#000',
    paddingBottom: 12,
    marginBottom: 8,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  content: {
    flexDirection: 'column',
    gap: 10,
  },

  bold: {
    fontWeight: 'bold',
  },
  greyColor: {
    color: '#666',
  },

  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '0.5px solid #000',
    borderBottom: '0.5px solid #000',
  },
  addressFrom: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '0.5px solid #000',
    padding: '12px 12px 12px 0',
  },
  addressTo: {
    flex: 1,
    padding: 12,
  },

  tableRow: {
    flexDirection: 'row',
    gap: 12,
    borderBottom: '0.5px solid #000',
  },
  tableHeader: {
    flexDirection: 'row',
    gap: 12,
    borderBottom: '0.5px solid #000',
    fontWeight: 'bold',
    borderTop: '2px solid #000',
  },
  tableCell: {
    flex: 1,
    padding: '5px 0',
  },
  footnote: {
    borderTop: '0.5px solid #000',
    marginTop: 'auto',
    paddingTop: 8,
    fontSize: 10,
    textAlign: 'right',
  },
});

export default function PDFPreviewPage({
  order,
  toAddress,
  currentPkgNum,
  totalPkgNum,
  shipDate,
  shipMethod,
  renderItems = [],
  stripeQuoteNumber,
}) {
  return (
    <Document>
      <Page size="LETTER" style={styles.page} dpi={96}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <View>
              <Image src="/images/Wordmark-1.0.0-positive-1000w.png" style={{ width: 92 }} />
            </View>
            <View style={{ lineHeight: 1.48, fontSize: 6, marginTop: -1 }}>
              <Text style={{ fontWeight: 'bold' }}>Parallel Fluidics</Text>
              <Text style={styles.greyColor}>info@parallelfluidics.com</Text>
              <Text style={styles.greyColor}>parallelfluidics.com</Text>
            </View>
            <View
              style={{
                fontWeight: 'medium', fontSize: 13, lineHeight: 1.05, marginTop: -3, marginLeft: 14,
              }}
            >
              <Text>Packing</Text>
              <Text>Slip</Text>
            </View>
          </View>
          <View style={{ ...styles.headerRight, marginTop: -4 }}>
            <Text style={{ fontWeight: 'bold', fontSize: 17, lineHeight: 1.24 }}>
              #
              {currentPkgNum}
            </Text>
            <Text style={{ ...styles.greyColor, fontSize: 7 }}>{`Shipped ${shipDate}`}</Text>
          </View>
        </View>

        <View style={styles.content}>

          {/* Title */}
          <View>
            <Text style={{ fontSize: 27, fontWeight: 'bold', lineHeight: 1.2 }}>PACKING LIST</Text>
            <Text>{`Pkg. ${currentPkgNum} of ${totalPkgNum}`}</Text>
          </View>

          {/* Address */}
          <View style={styles.addressContainer}>
            <View style={styles.addressFrom}>
              <Text style={styles.bold}>Parallel Fluidics</Text>
              <Text>840 Summer St</Text>
              <Text>Suite 001</Text>
              <Text>Boston, MA 02127</Text>
              <Text>US</Text>
              <Text>+1 508-989-9896</Text>
              <Text>accountspayable@parallelfluidics.com</Text>
            </View>
            <View style={styles.addressTo}>
              {formatAddress(toAddress).split('\n').map((line, index) => (index === 0 ? (
                <Text key={line} style={styles.bold}>{line}</Text>
              ) : (
                <Text key={line}>{line}</Text>
              )))}
            </View>
          </View>

          {/* Headings */}
          <View>
            <Text>
              <Tspan style={styles.bold}>
                Order Date:
              </Tspan>
              {' '}
              {order.orderDate}
            </Text>
            <Text>
              <Tspan style={styles.bold}>
                Associated Quote:
              </Tspan>
              {' '}
              {stripeQuoteNumber}
            </Text>
            <Text>
              <Tspan style={styles.bold}>
                Ship Date:
              </Tspan>
              {' '}
              {shipDate}
            </Text>
            <Text>
              <Tspan style={styles.bold}>
                Shipping:
              </Tspan>
              {' '}
              {shipMethod}
            </Text>
          </View>

          {/* Table */}
          <View style={{ marginTop: 14 }}>
            <View style={styles.tableHeader}>
              <Text style={{ ...styles.tableCell, flex: 3 }}>Description</Text>
              <Text style={styles.tableCell}>Quantity Ordered</Text>
              <Text style={styles.tableCell}>Quantity Shipped</Text>
            </View>
            {renderItems.map((item) => (
              <View key={item.id} style={styles.tableRow}>
                <View style={{ ...styles.tableCell, flex: 3 }}>
                  <Text>{item.name}</Text>
                  {item.description && <Text style={styles.greyColor}>{item.description}</Text>}
                </View>
                <Text style={styles.tableCell}>{item.quantity}</Text>
                <Text style={styles.tableCell}>{item.quantityShipped}</Text>
              </View>
            ))}
          </View>
        </View>

        {/* Footnote */}
        <Text style={styles.footnote}>1</Text>
      </Page>
    </Document>
  );
}

PDFPreviewPage.propTypes = {
  order: PropTypes.shape({
    orderDate: PropTypes.string,
    shipments: PropTypes.arrayOf(PropTypes.shape({
      shippingMethod: PropTypes.string,
    })),
  }).isRequired,
  toAddress: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  shipDate: PropTypes.string.isRequired,
  shipMethod: PropTypes.string.isRequired,
  currentPkgNum: PropTypes.number.isRequired,
  totalPkgNum: PropTypes.number.isRequired,
  renderItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    quantityShipped: PropTypes.number,
  })),
  stripeQuoteNumber: PropTypes.string.isRequired,
};
