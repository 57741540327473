import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import { useTheme } from '../../../../features/theme/themeProvider';
import Inputs from './addressInputs';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

export default function AddressInputs(props) {
  const { isDarkMode } = useTheme();

  const elementsOptions = {
    // custom css to make stripe input looks like MUI inputs
    appearance: {
      theme: isDarkMode ? 'night' : 'stripe',
    },
  };

  return (
    <Elements stripe={stripe} options={elementsOptions}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Inputs {...props} />
    </Elements>
  );
}
