import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { usePLCReady, usePLCValue } from '../../../features/plc/context';
import Gauge, { GaugeSkeleton } from '../../Gauge';
import { titleify, useHFTSHandle, useProgramHandle } from '../hooks/context';

function HeaterStatus({ sideHandle, heaterHandle }) {
  const programHandle = useProgramHandle();
  const hftsHandle = useHFTSHandle(sideHandle);
  const heaterName = useMemo(() => `${heaterHandle} ${titleify(sideHandle)} Heater`, [heaterHandle, sideHandle]);

  const heaterTemp = usePLCValue(`${programHandle}.${sideHandle}${heaterHandle}RTD`);
  const cylinderEngaged = usePLCValue(`${programHandle}.${sideHandle}${heaterHandle}Cylinder`);
  const heaterOn = usePLCValue(`${programHandle}.${sideHandle}${heaterHandle}Heater`);

  const targetTempHandle = useMemo(() => {
    if (heaterHandle === 'Hot') {
      return `${hftsHandle}.f${heaterHandle}BlockSetpoint`;
    }
    return `${hftsHandle}.f${heaterHandle}Heater${cylinderEngaged ? 'Engaged' : 'Disengaged'}Setpoint`;
  }, [heaterHandle, hftsHandle, cylinderEngaged]);
  const plcTargetTemp = usePLCValue(targetTempHandle);
  const targetTemp = heaterHandle === 'Hot' || !cylinderEngaged ? plcTargetTemp : 'N/A';

  const maxTemp = usePLCValue(`${hftsHandle}.f${heaterHandle}BlockTempMax`);
  const shutoffTemp = usePLCValue(`${hftsHandle}.f${heaterHandle}BlockTempShutoff`);

  const bands = useMemo(() => {
    const retBands = [];
    if (shutoffTemp) {
      retBands.push({
        id: 'shutoff',
        start: shutoffTemp,
        end: maxTemp,
        color: 'high',
      });
    }
    if (targetTemp && (!cylinderEngaged || heaterHandle === 'Hot')) {
      retBands.push({
        id: 'target',
        start: targetTemp - 5,
        end: targetTemp + 5,
        color: 'positive',
      });
    }
    return retBands;
  }, [shutoffTemp, maxTemp, targetTemp, cylinderEngaged]);

  const ready = usePLCReady();

  if (!ready) {
    return <GaugeSkeleton title={heaterName} />;
  }
  return (
    <Gauge
      title={heaterName}
      temp={(heaterTemp / 10) || 0}
      max={maxTemp || 475}
      bands={bands}
      target={targetTemp || 'N/A'}
      cylinderUp={cylinderEngaged}
      heaterOn={heaterOn}
    />
  );
}

HeaterStatus.propTypes = {
  sideHandle: PropTypes.string.isRequired,
  heaterHandle: PropTypes.string.isRequired,
};

export default HeaterStatus;
