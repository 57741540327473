import { InspectionValues, StepTypes } from '@parallel-fluidics/constants';
import { calculateBusinessDays, formatDate } from '@parallel-fluidics/shipping';
import {
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import {
  useFirestoreCollectionData,
} from 'reactfire';

import OrderStatus from '../../constants/orderStatus';

const useActiveProjects = (limit) => {
  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('status', '==', OrderStatus.IN_PROGRESS),
  ));
  return useMemo(() => {
    const projects = [];
    (activeOrders || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        if (shipment.isShipped) return;
        (shipment?.lineItems || []).forEach((lineItem) => {
          projects.push({
            lineItemId: lineItem.id,
            organization: order.organization,
            projectNumber: lineItem.projectId,
            shipDate: shipment.targetShipDate,
            steps: lineItem.steps,
            notes: lineItem.notes,
          });
        });
      });
    });
    projects.sort((a, b) => new Date(a.shipDate).getTime() - new Date(b.shipDate).getTime());
    return projects.slice(0, limit);
  }, [activeOrders, limit]);
};

const useShippedProjects = () => {
  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('hasShippedItems', '==', true),
  ));
  return useMemo(() => {
    const projects = [];
    (activeOrders || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        if (!shipment.isShipped) return;
        (shipment?.lineItems || []).forEach((lineItem) => {
          if (lineItem.steps[0]?.goal > 0) {
            const lastTrackingData = shipment.trackingData?.[shipment.trackingData.length - 1];
            const trackShipDate = lastTrackingData?.shipDate
              ? formatDate(lastTrackingData.shipDate)
              : null;

            projects.push({
              lineItemId: lineItem.id,
              organization: order.organization,
              projectNumber: lineItem.projectId,
              shipDate: shipment.targetShipDate,
              shipDateActual: shipment.shipDate || trackShipDate,
              steps: lineItem.steps,
              notes: lineItem.notes,
            });
          }
        });
      });
    });
    projects.sort((a, b) => new Date(b.shipDate).getTime() - new Date(a.shipDate).getTime());
    return projects;
  }, [activeOrders]);
};

const useProjectStatus = (lineItemSteps, lineItemId = 'nonexistent') => {
  const memoizedQuery = useMemo(() => query(
    collection(getFirestore(), 'travelers'),
    where('lineItem', '==', lineItemId),
  ), [lineItemId]);

  const { status: queryStatus, data: travelers } = useFirestoreCollectionData(memoizedQuery, { idField: 'id' });
  const projectStatus = useMemo(() => {
    if (queryStatus === 'success') {
      const initializePart = (step) => ({
        passed: 0,
        toBeInspected: 0,
        onDeck: 0,
        rejectCosmetic: 0,
        rejectMajor: 0,
        tuning: 0,
        unknown: 0,
        stepYield: 0,
        chipData: [],
        type: step.type,
      });
      // use index instead of name, because name is not unique
      const retParts = lineItemSteps?.map(initializePart) || {};
      if (!travelers.length) return retParts;

      travelers.forEach((traveler) => {
        let skipCalc = false;
        traveler.steps?.forEach((step, index) => {
          // for current step with inspected value, we still need to push to chipData for display
          if (skipCalc && step.inspected === InspectionValues.NOT_INSPECTED) return;
          const lastRun = step.runId?.[step.runId.length - 1];

          const addChipData = (curInspection) => {
            retParts[index].chipData.push({
              travelerId: traveler.id,
              prevInspection: traveler.steps[index - 1]?.isOnDeck ? 'On deck' : (traveler.steps[index - 1]?.inspected || InspectionValues.PASSED),
              curInspection,
              runStartDate: lastRun?.start?.toDate(),
              runEndDate: lastRun?.end?.toDate(),
              press: lastRun?.press,
              processId: lastRun?.id,
              // eslint-disable-next-line no-underscore-dangle
              sortDate: lastRun?.start || traveler._createdBy.timestamp,
            });
          };

          const addToDeck = () => {
            retParts[index].onDeck += 1;
            addChipData('On deck');
            // mark isOnDeck for next step display purpose
            // eslint-disable-next-line no-param-reassign
            step.isOnDeck = true;
            skipCalc = true;
          };

          // check if it's on deck for MOLDING and BONDING
          if (
            !skipCalc
            && !step.runId?.length
            && [StepTypes.MOLDING, StepTypes.BONDING].includes(step.type)
          ) {
            addToDeck();
            return;
          }

          if (step.inspected === InspectionValues.NOT_INSPECTED) {
            if (!skipCalc) {
              // for POSTMACHINING and CONNECTORIZING, we want show on deck instead of toBeInspected
              if ([StepTypes.POSTMACHINING, StepTypes.CONNECTORIZING].includes(step.type)) {
                addToDeck();
                return;
              }
              retParts[index].toBeInspected += 1;
            }
            skipCalc = true;
          } else if (step.inspected === InspectionValues.PASSED) {
            if (!skipCalc) retParts[index].passed += 1;
          } else if (step.inspected === InspectionValues.REJECT_MAJOR) {
            if (!skipCalc) retParts[index].rejectMajor += 1;
            skipCalc = true;
          } else if (step.inspected === InspectionValues.REJECT_COSMETIC) {
            if (!skipCalc) retParts[index].rejectCosmetic += 1;
            skipCalc = true;
          } else if (step.inspected === InspectionValues.TUNING) {
            // Calculate the turning count independently of the previous stage
            retParts[index].tuning += 1;
            skipCalc = true;
          } else {
            if (!skipCalc) retParts[index].unknown += 1;
            skipCalc = true;
          }
          addChipData(step.inspected);
        });
      });

      // calculate step yield rate
      Object.keys(retParts).forEach((key) => {
        const part = retParts[key];
        part.stepYield = Math.round(
          (part.passed / ((part.passed + part.rejectCosmetic + part.rejectMajor) || 1)) * 100,
        );
        // sort chipData by sortDate
        part.chipData.sort((a, b) => a.sortDate - b.sortDate);
      });

      // calculate total yield rate
      const totalY = Object.values(retParts).reduce(
        (acc, part) => acc * (part.stepYield / 100),
        1,
      );
      retParts.totalYield = Math.round(totalY * 100);
      return retParts;
    }
    return undefined;
  }, [queryStatus, lineItemSteps, travelers]);
  return { projectStatus, queryStatus };
};

const useShipDate = (targetShipDate, shipDateActual = null) => useMemo(() => {
  const daysUntilShip = Math.ceil((new Date(`${shipDateActual || targetShipDate} 00:00:00`) - new Date()) / (1000 * 60 * 60 * 24));
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  const fromNow = rtf.format(daysUntilShip, 'day');
  let baseTextPrefix;
  if (shipDateActual) {
    baseTextPrefix = 'Shipped';
  } else if (daysUntilShip < 0) {
    baseTextPrefix = 'Scheduled to ship';
  } else {
    baseTextPrefix = 'Ships';
  }
  const businessDaysUntilShip = calculateBusinessDays(targetShipDate);
  const baseText = `${baseTextPrefix} ${fromNow}${(businessDaysUntilShip > 0 && daysUntilShip !== businessDaysUntilShip) ? ` (${businessDaysUntilShip} business days)` : ''}`;
  if (shipDateActual && shipDateActual !== targetShipDate) {
    const diffInDays = Math.ceil((new Date(`${targetShipDate} 00:00:00`) - new Date(`${shipDateActual} 00:00:00`)) / (1000 * 60 * 60 * 24));
    return `${baseText} (${Math.abs(diffInDays)} days ${diffInDays > 0 ? 'early' : 'late'})`;
  }
  return baseText;
}, [shipDateActual, targetShipDate]);

export {
  useActiveProjects,
  useShippedProjects,
  useShipDate,
  useProjectStatus,
};
