import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import AddressDisplay from './addressDisplay';
import { usePackage } from './packageProvider';

export default function PackingSlipForm({ onNext, onCreate }) {
  const {
    order,
    selectedShipment,
    setSelectedShipment,
    toAddress,
    setToAddress,
    selectedLineItems,
    setSelectedLineItems,
    currentPkgNum,
    setCurrentPkgNum,
    totalPkgNum,
    setTotalPkgNum,
    stripeQuoteNumber,
    setStripeQuoteNumber,
    shipDate,
    setShipDate,
    shipMethod,
    setShipMethod,
    renderItems,
    handleUpload,
    handleUpdateOrder,
  } = usePackage();

  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = useCallback(async () => {
    try {
      setIsLoading(true);
      const createDate = new Date().toISOString();
      const packingSlipUrl = await handleUpload(`manual_${createDate}`);
      const trackingData = {
        trackingCode: null,
        trackingUrl: null,
        shippingLabelUrl: null,
        shipmentId: null,
        shipDate: createDate,
        packingSlipUrl,
        shippedItems: renderItems.map(
          (item) => ({ id: item.id, quantityShipped: item.quantityShipped }),
        ),
      };
      await handleUpdateOrder(trackingData);
      onCreate();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating packing slip', error);
    } finally {
      setIsLoading(false);
    }
  }, [handleUpdateOrder, handleUpload, onCreate, renderItems]);

  const lineItemRows = useMemo(() => selectedLineItems.map((item) => ({
    ...item,
    nameDisplay: (
      <div>
        <div>{item.name}</div>
        {item.description && <Box color="text-status-inactive" variant="small">{item.description}</Box>}
      </div>
    ),
    checkBox: (
      <Checkbox
        onChange={() => {
          setSelectedLineItems((prev) => prev.map((i) => (i.id === item.id
            ? { ...i, isChecked: !i.isChecked }
            : i)));
        }}
        checked={item.isChecked}
      />
    ),
    shippedInput: (
      <Input
        value={item.quantityShipped}
        type="number"
        onChange={({ detail }) => {
          setSelectedLineItems((prev) => prev.map((i) => {
            if (i.id === item.id) {
              return {
                ...i,
                quantityShipped: parseInt(detail.value, 10),
              };
            }
            return i;
          }));
        }}
      />
    ),
  })), [selectedLineItems, setSelectedLineItems]);

  return (
    <Container
      header={<Header>Packing Slip</Header>}
    >
      <SpaceBetween size="m">
        {order.shipments.length > 1 && !!selectedShipment && (
        <FormField label="Select Shipment">
          <Select
            selectedOption={{ value: selectedShipment.index, label: `${selectedShipment.name} (${selectedShipment.targetShipDate})` }}
            onChange={({ detail }) => {
              const idx = detail.selectedOption.value;
              setSelectedShipment({ ...order.shipments[idx], index: idx });
            }}
            options={order.shipments.map((s, index) => ({
              value: index,
              label: `${s.name} (${s.targetShipDate})`,
            }))}
          />
        </FormField>
        )}

        <FormField label="Shipping Address">
          <AddressDisplay
            backupAddress={selectedShipment?.address}
            address={toAddress}
            onEdit={setToAddress}
          />
        </FormField>

        <ColumnLayout columns={2}>
          <FormField label="Current Package Number">
            <Input
              type="number"
              value={currentPkgNum}
              onChange={({ detail }) => setCurrentPkgNum(parseInt(detail.value, 10))}
            />
          </FormField>
          <FormField label="Total Package Number">
            <Input
              type="number"
              value={totalPkgNum}
              onChange={({ detail }) => setTotalPkgNum(parseInt(detail.value, 10))}
            />
          </FormField>
        </ColumnLayout>

        <FormField label="Associated Quote" errorText={!stripeQuoteNumber && 'Please enter Quote Number'}>
          <Input
            value={stripeQuoteNumber}
            onChange={({ detail }) => setStripeQuoteNumber(detail.value)}
          />
        </FormField>

        <FormField label="Ship Date" errorText={!shipDate && 'Please enter Ship Date'}>
          <Input
            value={shipDate}
            onChange={({ detail }) => setShipDate(detail.value)}
          />
        </FormField>

        <FormField label="Shipping Method" errorText={!shipMethod && 'Please enter Shipping Method'}>
          <Input
            value={shipMethod}
            onChange={({ detail }) => setShipMethod(detail.value)}
          />
        </FormField>

        <Table
          header={<Header variant="h3">Edit Packed Items</Header>}
          wrapLines
          columnDefinitions={[
            {
              id: 'checkBox',
              header: '',
              cell: (item) => item.checkBox,
            },
            {
              id: 'nameDisplay',
              header: 'Description',
              cell: (item) => item.nameDisplay,
              maxWidth: 200,
              isRowHeader: true,
            },
            {
              id: 'quantity',
              header: 'Quantity Ordered',
              cell: (item) => item.quantity,
            },
            {
              id: 'quantityShipped',
              header: 'Quantity Shipped',
              cell: (item) => item.shippedInput,
            },
          ]}
          items={lineItemRows}
        />

        <div style={{ textAlign: 'right' }}>
          {toAddress?.country === 'US' ? (
            <Button variant="primary" onClick={onNext}>Next</Button>
          ) : (
            <SpaceBetween size="xxs">
              <Button loading={isLoading} variant="primary" onClick={handleCreate}>Create Packing Slip</Button>
              <Box color="text-status-inactive" fontSize="body-s">We do not support purchasing shipping labels for international addresses.</Box>
            </SpaceBetween>
          )}
        </div>
      </SpaceBetween>
    </Container>
  );
}

PackingSlipForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};
