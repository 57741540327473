import {
  Box,
  FormField,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useFirestoreDocData } from 'reactfire';

import { usePLCValue } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

export default function FirebaseSession() {
  const programHandle = useProgramHandle();
  const firebaseSessionId = usePLCValue(`${programHandle}.sFirebaseSessionID`);

  const { data: session } = useFirestoreDocData(doc(getFirestore(), 'process_logs', firebaseSessionId || 'nonexistant'));
  const [observations, setObservations] = useState('');

  useEffect(() => {
    setObservations(session?.observations || '');
  }, [session?.observations]);

  const debouncedUpdateFirebase = useCallback(
    debounce((newValue) => {
      updateDoc(doc(getFirestore(), 'process_logs', firebaseSessionId), {
        observations: newValue,
      });
    }, 1000),
    [firebaseSessionId],
  );

  return (
    <SpaceBetween size="m">
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Box variant="awsui-key-label">Project</Box>
          <div>{session ? session.project : 'No active session'}</div>
        </div>
        <div style={{ flex: 1 }}>
          <Box variant="awsui-key-label">Number</Box>
          <div>{session ? session.number : 'No active session'}</div>
        </div>
      </div>
      {session?.currentStepNote && (
        <div>
          <Box variant="awsui-key-label">Step Note</Box>
          <div style={{ whiteSpace: 'pre-wrap' }}>{session.currentStepNote}</div>
        </div>
      )}
      <div>
        <Box variant="awsui-key-label">Hypothesis</Box>
        <div style={{ whiteSpace: 'pre-wrap' }}>{session ? session.hypothesis : 'No active session'}</div>
      </div>

      <FormField label="Observations">
        <Textarea
          rows={4}
          disabled={!session}
          value={observations}
          onChange={({ detail }) => {
            setObservations(detail.value);
            debouncedUpdateFirebase(detail.value);
          }}
        />
      </FormField>
    </SpaceBetween>
  );
}
