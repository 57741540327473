import './input.css';

import {
  Button, SpaceBetween, StatusIndicator,
} from '@cloudscape-design/components';
import { AddressElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const AddressSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone number is required'),
  street1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip Code is required'),
  country: Yup.string().required('Country is required'),
});
export default function AddressInputs({
  defaultAddress = {
    name: '',
    company: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  onSubmit = () => {},
}) {
  return (
    <Formik
      initialValues={defaultAddress}
      onSubmit={onSubmit}
      validationSchema={AddressSchema}
    >
      {({
        values, errors, setFieldValue, handleSubmit,
      }) => (
        <SpaceBetween size="m">
          <div>
            <div className="stripe-input-label">Company</div>
            <input
              type="text"
              value={values.company}
              onChange={(e) => setFieldValue('company', e.target.value)}
              className="stripe-input"
            />
          </div>
          <AddressElement
            options={{
              mode: 'shipping',
              fields: {
                phone: 'always',
              },
              validation: {
                phone: {
                  required: 'always',
                },
              },
              defaultValues: {
                name: values.name,
                phone: values.phone,
                address: {
                  line1: values.street1,
                  line2: values.street2,
                  city: values.city,
                  state: values.state,
                  postal_code: values.zip,
                  country: values.country,
                },
              },
              autocomplete: {
                mode: 'google_maps_api',
                apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
              },
            }}
            onChange={({ complete, value }) => {
              if (complete) {
                const {
                  address, name, phone,
                } = value;
                setFieldValue('name', name);
                setFieldValue('street1', address.line1);
                setFieldValue('street2', address.line2);
                setFieldValue('city', address.city);
                setFieldValue('state', address.state);
                setFieldValue('zip', address.postal_code);
                setFieldValue('country', address.country);
                setFieldValue('phone', phone);
              } else {
                // if address is not complete, set zip to empty to block the form submit
                setFieldValue('zip', '');
              }
            }}
          />
          {Object.keys(errors).length > 0 && (
            <StatusIndicator type="error">Please fill in all the fields.</StatusIndicator>
          )}
          <div style={{ textAlign: 'right', marginTop: '16px' }}>
            <Button variant="primary" formAction="none" onClick={handleSubmit}>Submit</Button>
          </div>
        </SpaceBetween>
      )}

    </Formik>
  );
}

AddressInputs.propTypes = {
  defaultAddress: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};
