import {
  Badge,
  Box,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import { InspectionValues, ProcessStepMap } from '@parallel-fluidics/constants';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useGetTravelersByIds } from '../../hooks/use-basicQueries';

const InspectionStatusMap = Object.freeze({
  [InspectionValues.NOT_INSPECTED]: 'pending',
  [InspectionValues.PASSED]: 'success',
  [InspectionValues.REJECT_MAJOR]: 'error',
  [InspectionValues.REJECT_COSMETIC]: 'warning',
  [InspectionValues.TUNING]: 'info',
});

function StatusDisplay({ step }) {
  return (
    <SpaceBetween size="xxxs">
      <StatusIndicator type={InspectionStatusMap[step.inspected] || 'info'}>
        {step.inspected}
      </StatusIndicator>
      {step.failureTags?.length > 0 && (
        <SpaceBetween direction="horizontal" size="xxxs">
          {step.failureTags.map((tag) => (
            <Badge key={tag} color="red">{tag}</Badge>
          ))}
        </SpaceBetween>
      )}
      {!!step.inspectedNote && <Box variant="small" display="block">{`Note: ${step.inspectedNote}`}</Box>}
    </SpaceBetween>
  );
}
StatusDisplay.propTypes = {
  step: PropTypes.shape({
    inspected: PropTypes.string.isRequired,
    failureTags: PropTypes.arrayOf(PropTypes.string),
    inspectedNote: PropTypes.string,
  }).isRequired,
};

export default function TravelerStatus({ travelerIds, process, stepIndex = 0 }) {
  const { data: travelers = [], isFetching } = useGetTravelersByIds(travelerIds);

  const stepsArr = useMemo(() => {
    if (travelers.length === 0) return [];
    if (stepIndex === 0) {
      // find the first step of the process
      return travelers.map((traveler) => {
        const step = traveler.steps.find((s) => s.type === ProcessStepMap[process]);
        return step;
      });
    }
    return travelers.map((traveler) => {
      const step = traveler.steps[stepIndex];
      return step;
    });
  }, [travelers, process, stepIndex]);

  if (isFetching) return <div>Loading...</div>;

  if (stepsArr.length === 0) return null;

  // show the status if there is only one traveler
  if (stepsArr.length === 1) {
    return (
      <StatusDisplay step={stepsArr[0]} />
    );
  }

  // show the pass rate if more than one traveler
  const passedCount = stepsArr.filter((s) => s.inspected === InspectionValues.PASSED).length;
  const passRate = Math.round((passedCount / stepsArr.length) * 100);
  return (
    <Popover
      content={(
        <SpaceBetween direction="horizontal" size="xs">
          {stepsArr.map((step, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <StatusDisplay key={i} step={step} />
          ))}
        </SpaceBetween>
      )}
      position="top"
    >
      {passRate}
      %
    </Popover>
  );
}

TravelerStatus.propTypes = {
  travelerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  process: PropTypes.string.isRequired,
  stepIndex: PropTypes.number,
};
