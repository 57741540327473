import {
  Button,
  Container,
  FileUpload,
  Header,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useNotifications } from '../../features/notifications';

function FileUploader({
  uploadPath,
  uploadedFiles,
  uploadErrors,
  onEmpty,
  onUpload,
  fileTypeName = 'File',
}) {
  const { addNotification } = useNotifications();

  const [selectedFiles, setSelectedFiles] = useState(() => {
    if (!uploadedFiles?.length) return [];
    // Try to convert the existing uploadedFiles to a File object for display purposes
    return uploadedFiles.map((file) => {
      const { name, type, lastModifiedTS } = file;
      return new File([new Blob([''], { type })], name, {
        type,
        lastModified: lastModifiedTS,
      });
    });
  });
  const [uploading, setUploading] = useState(false);

  // convert uploadErrors to array of strings
  const fileErrors = useMemo(() => {
    // Check if the input is an array of objects
    if (Array.isArray(uploadErrors) && uploadErrors.length > 0 && typeof uploadErrors[0] === 'object') {
      // Convert array of objects to array of strings
      return uploadErrors.map((errorObj) => {
        const errorStringArr = Object.entries(errorObj).map(([key, value]) => `${key} - ${value}`);
        return errorStringArr.join('; ');
      });
    }
    // Return the array as is if it's already an array of strings
    return uploadErrors;
  }, [uploadErrors]);

  const handleFileUploadChange = useCallback(({ detail }) => {
    if (!detail.value?.length) {
      onEmpty();
      setSelectedFiles(detail.value);
      return;
    }

    setUploading(true);
    const uploadPromises = detail.value.map(async (file) => {
      const filePath = `${uploadPath}/${file.name}`;
      const fileRef = ref(getStorage(), filePath);
      try {
        // Upload file
        await uploadBytes(fileRef, file);

        // Get download URL
        const downloadURL = await getDownloadURL(fileRef);
        // return data in the format we want to save
        return {
          downloadURL,
          lastModifiedTS: file.lastModified,
          name: file.name,
          ref: filePath,
          type: file.type,
        };
      } catch (error) {
        addNotification({
          type: 'error',
          dismissible: true,
          content: `Error uploading file to firebase: ${error.message}`,
          id: 'file-upload-error',
        });
        return {};
      }
    });

    Promise.all(uploadPromises)
      .then((data) => {
        onUpload(data);
        setSelectedFiles(detail.value);
      })
      .finally(() => {
        setUploading(false);
      });
  }, [onEmpty, uploadPath, addNotification, onUpload]);

  return (
    <Container
      header={(<Header>{`Upload ${fileTypeName}`}</Header>)}
    >
      <SpaceBetween size="xs">
        {uploading && <Spinner size="large" />}
        <FileUpload
          i18nStrings={{
            uploadButtonText: (e) => (e ? 'Choose files' : 'Choose a file'),
            dropzoneText: (e) => (e
              ? 'Drop files to upload'
              : 'Drop file to upload'),
            removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            errorIconAriaLabel: 'Error',
          }}
          accept=".pdf, .doc, .docx"
          onChange={handleFileUploadChange}
          value={selectedFiles}
          showFileLastModified
          showFileSize
          showFileThumbnail
          tokenLimit={1}
          fileErrors={fileErrors}
          constraintText="Please upload a PDF or Word document"
        />
        {uploadedFiles?.[0]?.downloadURL && (
          <Button
            href={uploadedFiles[0].downloadURL}
            target="_blank"
            iconName="external"
            iconAlign="right"
          >
            {`${fileTypeName} Details`}
          </Button>
        )}
      </SpaceBetween>
    </Container>
  );
}
FileUploader.propTypes = {
  uploadPath: PropTypes.string.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    lastModifiedTS: PropTypes.number,
    downloadURL: PropTypes.string,
  })).isRequired,
  uploadErrors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  onEmpty: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  fileTypeName: PropTypes.string,
};

export default FileUploader;
