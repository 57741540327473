import {
  AppLayout,
  BreadcrumbGroup,
  Flashbar,
  SideNavigation,
} from '@cloudscape-design/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import {
  connectFirestoreEmulator,
  doc,
  getFirestore,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
} from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  useFirebaseApp,
  useFirestoreDocData,
} from 'reactfire';

import Navigation from './components/Navigation';
import NotificationsProvider, { useNotifications } from './features/notifications';
import { useGetUserById } from './hooks/use-basicQueries';
import UserDataProvider, { useUserData } from './userDataProvider';

const queryClient = new QueryClient();

function Breadcrumbs() {
  const location = useLocation();
  const partId = useMemo(() => {
    const pathnames = (location?.pathname || '').split('/').filter((x) => x);
    if (pathnames.length === 0) {
      return null;
    }
    const category = pathnames[0];
    if (category === 'part' || category === 'parts') {
      return pathnames[1];
    }
    return null;
  }, [location]);
  const { data: part } = useFirestoreDocData(doc(getFirestore(), 'parts', partId || 'nonexistent'));
  const userId = useMemo(() => {
    if (part?.uid) {
      return part.uid;
    }
    const pathnames = (location?.pathname || '').split('/').filter((x) => x);
    if (pathnames.length === 2 && pathnames[0] === 'user') {
      return pathnames[1];
    }
    return null;
  }, [part, location]);

  const { data: { email: userEmailFromId } = {} } = useGetUserById(userId);

  const userEmail = useMemo(
    () => userEmailFromId || part?.email || userId,
    [part, userEmailFromId, userId],
  );

  const breadcrumbItems = useMemo(() => {
    const pathnames = (location?.pathname || '').split('/').filter((x) => x);
    if (pathnames.length === 0) {
      return [];
    }
    const category = pathnames[0];
    switch (category) {
      case 'part':
      case 'parts': {
        let breadcrumbs = [
          { text: 'Home', href: '/' },
          { text: 'Parts', href: '/parts' },
        ];

        if (pathnames.length === 1) {
          return breadcrumbs;
        }
        breadcrumbs = [
          ...breadcrumbs,
          { text: `${userEmail}'s parts`, href: `/user/${userId}` },
          { text: `Quote #${partId.substring(0, 6).toUpperCase()}`, href: `/part/${partId}` },
        ];
        if (pathnames.length === 2) {
          return breadcrumbs;
        }
        switch (pathnames[2]) {
          case 'feedback': {
            return [
              ...breadcrumbs,
              { text: 'Feedback', href: `/part/${partId}/feedback` },
            ];
          }
          case 'pricing': {
            return [
              ...breadcrumbs,
              { text: 'Pricing', href: `/part/${partId}/pricing` },
            ];
          }
          case 'cad': {
            return [
              ...breadcrumbs,
              { text: 'CAD Studio', href: `/part/${partId}/cad` },
            ];
          }
          default: {
            return breadcrumbs;
          }
        }
      }
      case 'user': {
        if (userId) {
          return [
            { text: 'Home', href: '/' },
            { text: 'Parts', href: '/parts' },
            { text: `${userEmail}'s parts`, href: `/user/${userId}` },
          ];
        }
        return [];
      }
      default: {
        return [];
      }
    }
  }, [location, partId, userId, userEmail]);

  const navigate = useNavigate();

  if (breadcrumbItems.length === 0) {
    return null;
  }

  return (
    <BreadcrumbGroup
      onClick={(e) => {
        e.preventDefault();
        const { href } = e.detail;
        if (href) {
          navigate(href);
        }
      }}
      items={breadcrumbItems}
    />
  );
}

function App() {
  const { user } = useUserData();

  // for notifications
  const { notifications } = useNotifications();

  // for side navigation
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationOpen, setNavigationOpen] = useState(false);

  return (
    <>
      <ScrollRestoration />
      <Navigation />
      <AppLayout
        toolsHide
        breadcrumbs={(user?.email || '').includes('parallelfluidics.com') ? <Breadcrumbs /> : null}
        content={<Outlet />}
        notifications={notifications.length > 0
          ? (
            <Flashbar
              items={notifications.filter(
                (notification) => notification.location === location.pathname,
              )}
            />
          ) : null}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        navigation={(user?.email || '').includes('parallelfluidics.com') ? (
          <SideNavigation
            activeHref={location}
            onFollow={(event) => {
              if (!event.detail.external) {
                event.preventDefault();
                navigate(event.detail.href);
                setNavigationOpen(false);
              }
            }}
            items={[
              {
                type: 'section-group',
                title: 'HMI',
                items: [
                  {
                    type: 'link',
                    text: 'Press 1',
                    href: '/live/press1',
                  },
                  {
                    type: 'link',
                    text: 'Press 2',
                    href: '/live/press2',
                  },
                  {
                    type: 'link',
                    text: 'Press 3',
                    href: '/live/press3',
                  },
                  {
                    type: 'link',
                    text: 'Press 4',
                    href: '/live/press4',
                  },
                ],
              },
              { type: 'divider' },
              {
                type: 'section-group',
                title: 'Process Data',
                items: [
                  {
                    type: 'link',
                    text: 'Forming',
                    href: '/process-data/forming',
                  },
                  {
                    type: 'link',
                    text: 'Bonding',
                    href: '/process-data/bonding',
                  },
                ],
              },
              { type: 'divider' },
              {
                type: 'section-group',
                title: 'Management',
                items: [
                  {
                    type: 'link',
                    text: 'All Orders',
                    href: '/orders',
                  },
                  {
                    type: 'link',
                    text: 'Active Orders',
                    href: '/orders/active',
                  },
                  {
                    type: 'link',
                    text: 'Shipped Orders',
                    href: '/orders/shipped',
                  },
                  {
                    type: 'link',
                    text: 'Projects',
                    href: '/projects',
                  },
                  {
                    type: 'link',
                    text: 'Stripe Quotes',
                    href: '/stripe/quotes',
                  },
                ],
              },
            ]}
          />
        ) : null}
      />
    </>

  );
}

function WrappedApp() {
  const app = useFirebaseApp();
  const functions = getFunctions(app);
  const firestore = getFirestore(app);

  if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.log('Connecting to Functions emulator');
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  }
  if (process.env.REACT_APP_USE_FIRESTORE_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.log('Connecting to Firestore emulator');
    connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider sdk={getAuth(app)}>
        <FirestoreProvider sdk={firestore}>
          <FunctionsProvider sdk={functions}>
            <StorageProvider sdk={getStorage(app)}>
              <NotificationsProvider>
                <UserDataProvider>
                  <App />
                </UserDataProvider>
              </NotificationsProvider>
            </StorageProvider>
          </FunctionsProvider>
        </FirestoreProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default WrappedApp;
