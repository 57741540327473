import {
  Button,
  Container,
  Header,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useFunctions } from 'reactfire';

import { usePackage } from './packageProvider';

export default function SelectCarrier({ onNext, onPrev }) {
  const {
    shipmentId,
    rates,
    renderItems,
    handleUpload,
    handleUpdateOrder,
  } = usePackage();
  const functions = useFunctions();

  const [selectedRates, setSelectedRates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handlePurchaseLabel = useCallback(async () => {
    const purchaseShippingLabel = httpsCallable(functions, 'purchaseShippingLabel');
    const { data } = await purchaseShippingLabel({ shipmentId, rateId: selectedRates[0].id });
    const labelData = {
      trackingCode: data.tracking_code,
      trackingUrl: data.tracker.public_url,
      shipDate: data.tracker.created_at,
      carrier: data.tracker.carrier,
      shippingLabelUrl: data.postage_label.label_url,
      shipmentId: data.id,
    };
    return labelData;
  }, [functions, selectedRates, shipmentId]);

  const handleSkipPurchase = useCallback(async () => {
    try {
      setIsLoading(true);
      const packingSlipUrl = await handleUpload(`manual_${shipmentId}`);
      const trackingData = {
        trackingCode: null,
        trackingUrl: null,
        shippingLabelUrl: null,
        shipmentId,
        shipDate: new Date().toISOString(),
        packingSlipUrl,
        shippedItems: renderItems.map(
          (item) => ({ id: item.id, quantityShipped: item.quantityShipped }),
        ),
      };
      await handleUpdateOrder(trackingData);
      onNext();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error skipping purchase', error);
    } finally {
      setIsLoading(false);
    }
  }, [handleUpload, shipmentId, renderItems, handleUpdateOrder, onNext]);

  const handleNext = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!shipmentId || !selectedRates.length) return;
      const labelData = await handlePurchaseLabel();
      const packingSlipUrl = await handleUpload(labelData.trackingCode);

      const trackingData = {
        ...labelData,
        packingSlipUrl,
        shippedItems: renderItems.map(
          (item) => ({ id: item.id, quantityShipped: item.quantityShipped }),
        ),
      };
      await handleUpdateOrder(trackingData);

      onNext();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error purchasing label', error);
    } finally {
      setIsLoading(false);
    }
  }, [
    handlePurchaseLabel,
    handleUpdateOrder,
    handleUpload,
    onNext,
    selectedRates.length,
    shipmentId,
    renderItems,
  ]);

  const handlePrev = () => {
    setSelectedRates([]);
    onPrev();
  };

  if (!shipmentId) return null;

  return (
    <Container
      header={<Header>Select Carrier and Delivery Service</Header>}
    >
      <SpaceBetween size="m">
        <Table
          onSelectionChange={({ detail }) => setSelectedRates(detail.selectedItems)}
          selectedItems={selectedRates}
          variant="embedded"
          columnDefinitions={[
            {
              id: 'carrier',
              header: 'Carrier',
              cell: (item) => item.carrier,
              isRowHeader: true,
            },
            {
              id: 'service',
              header: 'Service Level',
              cell: (item) => item.service,
            },
            {
              id: 'days',
              header: 'Transit Days',
              cell: (item) => item.delivery_days,
            },
            {
              id: 'cost',
              header: 'Cost',
              cell: (item) => `$${item.rate}`,
            },
          ]}
          items={rates}
          selectionType="single"
          trackBy="id"
        />
        <div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
          <Button onClick={handlePrev}>Back</Button>
          <Button loading={isLoading} onClick={handleSkipPurchase}>Skip Purchase</Button>
          <Button variant="primary" loading={isLoading} onClick={handleNext}>
            {`Buy Label${selectedRates.length > 0 ? ` for $${selectedRates[0].rate}` : ''}`}
          </Button>
        </div>
      </SpaceBetween>
    </Container>
  );
}

SelectCarrier.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};
