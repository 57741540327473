import {
  SpaceBetween,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { usePLCReady, usePLCValue } from '../../../features/plc/context';
import Gauge, { GaugeSkeleton } from '../../Gauge';
import { titleify, useHFTSHandle, useProgramHandle } from '../hooks/context';
import useHFTSTarget from '../hooks/useHFTSTarget';
import { disengagedTempCalculations } from './hfts';
import PLCNumberInput from './plcNumberInput';

function TempControl({ side }) {
  const programHandle = useProgramHandle();
  const hftsHandle = useHFTSHandle(side);
  const target = useHFTSTarget(side);
  const gaugeTitle = useMemo(() => `${titleify(side)} Heater`, [side]);

  const temp = usePLCValue(`${programHandle}.${side}ToolRTD`);
  const lowerBand = usePLCValue(`${hftsHandle}.fSetpointLowerBand`);
  const upperBand = usePLCValue(`${hftsHandle}.fSetpointUpperBand`);

  const ready = usePLCReady();

  const bands = useMemo(() => {
    if (['N/A', 'Unknown'].includes(target)) {
      return [];
    }
    return [{
      id: 'target',
      start: target - lowerBand,
      end: target + upperBand,
      color: 'positive',
    }];
  }, [target, lowerBand, upperBand]);

  return (
    <SpaceBetween size="s">
      { !ready ? (
        <GaugeSkeleton title={gaugeTitle} />
      ) : (
        <Gauge
          title={gaugeTitle}
          temp={(temp / 10) || 0}
          max={475}
          bands={bands}
          target={target}
          showIndicators={false}
        />
      )}
      <PLCNumberInput
        label={`${side} Setpoint`}
        handle={`${hftsHandle}.fFrontHeaterEngagedSetpoint`}
        type="float"
        writeSideEffect={({ value, write }) => {
          const disengagedSetpoint = disengagedTempCalculations[side].front(value);
          write({ handle: `${hftsHandle}.fFrontHeaterDisengagedSetpoint`, value: disengagedSetpoint });
        }}
      />
      <PLCNumberInput
        label={`${side} Standby`}
        handle={`${hftsHandle}.fRearHeaterEngagedSetpoint`}
        type="float"
        writeSideEffect={({ value, write }) => {
          const disengagedSetpoint = disengagedTempCalculations[side].rear(value);
          write({ handle: `${hftsHandle}.fRearHeaterDisengagedSetpoint`, value: disengagedSetpoint });
        }}
      />
    </SpaceBetween>
  );
}

TempControl.propTypes = {
  side: PropTypes.oneOf(['Upper', 'Lower', 'CapSide', 'ChipSide']).isRequired,
};

export default TempControl;
