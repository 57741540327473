import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

const PricingSchemaContext = createContext(null);

function PricingSchemaProvider({ children }) {
  const [pricingSchema, setPricingSchema] = useState({
    multiplier: Yup.number().typeError('Multiplier is required').required('Multiplier is required'),
    breakpoints: Yup.array(Yup.object().shape({
      quantity: Yup.number().typeError('Quantity is required').required('Quantity is required'),
      multiplier: Yup.number().typeError('Multiplier is required').required('Multiplier is required'),
    })),
    reorderSetup: Yup.number().typeError('Reorder Setup is required').required('Reorder Setup is required'),
  });

  const updatePricingSchema = useCallback((key, schema) => {
    setPricingSchema((prev) => ({
      ...prev,
      [key]: schema,
    }));
  }, []);

  const pricingSchemaContextValue = useMemo(() => ({
    pricingSchema,
    updatePricingSchema,
  }), [pricingSchema, updatePricingSchema]);

  return (
    <PricingSchemaContext.Provider value={pricingSchemaContextValue}>
      {children}
    </PricingSchemaContext.Provider>
  );
}

PricingSchemaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PricingSchemaProvider;

export const usePricingSchema = () => {
  const context = useContext(PricingSchemaContext);
  if (context === undefined) {
    throw new Error('usePricingSchema must be used within a PricingSchemaProvider');
  }
  return context;
};
