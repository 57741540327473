import './sentry';
import './index.scss';
import '@cloudscape-design/global-styles/index.css';

import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en.json';
import { ProcessNames } from '@parallel-fluidics/constants';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';

import App from './App';
import RequireAuth from './components/RequireAuth';
import ThemeProvider from './features/theme/themeProvider';
import EditCADPage from './pages/EditCAD';
import EditFeedbackPage from './pages/EditFeedback';
import EditPricingPage from './pages/EditPricing';
import HMI from './pages/HMI';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import OrdersPage from './pages/Orders';
import ActiveOrdersPage from './pages/Orders/active';
import CreateOrderPage from './pages/Orders/create';
import CreatePackagePage from './pages/Orders/createPackage';
import ShippedOrdersPage from './pages/Orders/shipped';
import UpdateOrderPage from './pages/Orders/update';
import PartPage, { PartsListPage } from './pages/Part';
import SummaryPage, { ProcessDataPage } from './pages/ProcessData';
import ProjectsPage from './pages/Projects';
import StripeQuotesPage from './pages/Quotes';
import TravelerPage from './pages/Travelers';
import UserPage from './pages/User';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messageSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(
  createBrowserRouter,
);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <RequireAuth><HomePage /></RequireAuth>,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'user/:userID',
        element: <RequireAuth><UserPage /></RequireAuth>,
      },
      {
        path: 'parts',
        element: <RequireAuth><PartsListPage /></RequireAuth>,
      },
      {
        path: 'part/:partId',
        element: <RequireAuth><PartPage /></RequireAuth>,
      },
      {
        path: 'part/:partId/cad',
        element: <RequireAuth><EditCADPage /></RequireAuth>,
      },
      {
        path: 'part/:partId/feedback',
        element: <RequireAuth><EditFeedbackPage /></RequireAuth>,
      },
      {
        path: 'part/:partId/pricing',
        element: <RequireAuth><EditPricingPage /></RequireAuth>,
      },
      {
        path: 'orders',
        exact: true,
        element: <RequireAuth><OrdersPage /></RequireAuth>,
      },
      {
        path: 'orders/active',
        exact: true,
        element: <RequireAuth><ActiveOrdersPage /></RequireAuth>,
      },
      {
        path: 'orders/shipped',
        exact: true,
        element: <RequireAuth><ShippedOrdersPage /></RequireAuth>,
      },
      {
        path: 'orders/create',
        exact: true,
        element: <RequireAuth><CreateOrderPage /></RequireAuth>,
      },
      {
        path: 'orders/update/:orderId',
        exact: true,
        element: <RequireAuth><UpdateOrderPage /></RequireAuth>,
      },
      {
        path: 'package/create/:orderId',
        exact: true,
        element: <RequireAuth><CreatePackagePage /></RequireAuth>,
      },
      {
        path: 'projects',
        exact: true,
        element: <RequireAuth><ProjectsPage /></RequireAuth>,
      },
      {
        path: 'stripe/quotes',
        exact: true,
        element: <RequireAuth><StripeQuotesPage /></RequireAuth>,
      },
      {
        path: 'process-data/forming',
        element: <RequireAuth><SummaryPage processType={ProcessNames.FORMING} /></RequireAuth>,
        exact: true,
      },
      {
        path: 'process-data/forming/:sessionId',
        element: <RequireAuth><ProcessDataPage /></RequireAuth>,
        exact: true,
      },
      {
        path: 'process-data/bonding',
        element: <RequireAuth><SummaryPage processType={ProcessNames.BONDING} /></RequireAuth>,
        exact: true,
      },
      {
        path: 'process-data/bonding/:sessionId',
        element: <RequireAuth><ProcessDataPage /></RequireAuth>,
        exact: true,
      },
      {
        path: '/live/:pressName',
        element: <RequireAuth><HMI /></RequireAuth>,
        exact: true,
      },
      {
        path: '/travelers/:travelerId',
        element: <RequireAuth><TravelerPage /></RequireAuth>,
        exact: true,
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <I18nProvider locale="en" messages={[enMessages]}>
          <RouterProvider router={router} />
        </I18nProvider>
      </FirebaseAppProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
