import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useUser } from 'reactfire';

import { useGetUserById } from './hooks/use-basicQueries';

const UserDataContext = createContext();

// we should use useUserData() in our components to access the user DB data
// instead of using useUser() auth data
export default function UserDataProvider({ children }) {
  const { data: userAuth, status: authStatus } = useUser();
  const { data: user } = useGetUserById(userAuth?.uid);

  useEffect(() => {
    if (authStatus !== 'success') return;
    if (userAuth) {
      Sentry.setUser({
        id: userAuth?.uid,
        email: userAuth?.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [authStatus, userAuth]);

  const value = useMemo(() => ({
    user,
  }), [user]);

  // wait for auth data to load before rendering children
  if (authStatus === 'loading') return null;

  // if authUser and no user from db, return null
  if (userAuth && !user) return null;

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}
UserDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
