import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePackage } from './packageProvider';

export default function PackageDetail() {
  const { packageData, order } = usePackage();
  const navigate = useNavigate();

  return (
    <Container
      header={(
        <Header
          actions={<Button onClick={() => navigate(`/orders/update/${order.id}`)}>Back to order edit</Button>}
        >
          Package Created
        </Header>
      )}
      fitHeight
    >
      <SpaceBetween size="m">
        <div>Kindly print the label and ship the package as soon as possible. Thank you!</div>
        <div>
          <Button href={packageData.packingSlipUrl} target="_blank" rel="noreferrer" iconName="external" iconAlign="right">
            Print Packing Slip
          </Button>
        </div>
        {packageData.trackingCode && (
          <>
            <div>
              <Button href={packageData.shippingLabelUrl} target="_blank" rel="noreferrer" iconName="external" iconAlign="right">
                Print Shipping Label
              </Button>
            </div>
            <div>
              <Button href={packageData.trackingUrl} target="_blank" rel="noreferrer" iconName="external" iconAlign="right">
                Track Package
              </Button>
            </div>
            <div>
              Tracking Code:
              {' '}
              {packageData.trackingCode}
            </div>
          </>
        )}
      </SpaceBetween>
    </Container>
  );
}
