import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';

import { usePackage } from '../packageProvider';
import PDFPreviewPage from './pdfPreviewPage';

export default function PDFPreview() {
  const {
    order,
    toAddress,
    renderItems,
    currentPkgNum,
    totalPkgNum,
    shipDate,
    shipMethod,
    stripeQuoteNumber,
  } = usePackage();
  return (
    <PDFViewer width="100%" height="100%" showToolbar={false} style={{ border: 0 }}>
      <PDFPreviewPage
        order={order}
        toAddress={toAddress}
        renderItems={renderItems}
        currentPkgNum={currentPkgNum}
        totalPkgNum={totalPkgNum}
        shipDate={shipDate}
        shipMethod={shipMethod}
        stripeQuoteNumber={stripeQuoteNumber}
      />
    </PDFViewer>
  );
}
