import {
  Button,
  Container,
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PackageDetail from '../../components/Orders/package/packageDetail';
import PackageProvider from '../../components/Orders/package/packageProvider';
import PackingSlipForm from '../../components/Orders/package/packingSlipForm';
import ParcelForm from '../../components/Orders/package/parcelForm';
import PDFPreview from '../../components/Orders/package/pdfPreview';
import PDFViewer from '../../components/Orders/package/pdfViewer';
import SelectCarrier from '../../components/Orders/package/selectCarrier';

const Steps = Object.freeze({
  PACKING_SLIP: 'Packing Slip',
  PARCEL: 'Parcel',
  SELECT_RATE: 'Select Rate',
  CONFIRM: 'Confirm',
});
export default function CreatePackagePage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(Steps.PACKING_SLIP);

  return (
    <PackageProvider>
      <ContentLayout
        header={(
          <Header
            actions={(
              <Button
                variant="normal"
                onClick={() => { navigate('/orders'); }}
              >
                See all orders
              </Button>
            )}
          >
            Create Package
          </Header>
        )}
      >
        <div style={{ display: 'flex', gap: 16, height: 'calc(100vh - 48px - 104px)' }}>
          <div style={{ flex: 1, width: '50%' }}>
            {step === Steps.PACKING_SLIP && (
              <PackingSlipForm
                onNext={() => setStep(Steps.PARCEL)}
                onCreate={() => setStep(Steps.CONFIRM)}
              />
            )}
            {step === Steps.PARCEL && (
              <ParcelForm
                onNext={() => setStep(Steps.SELECT_RATE)}
                onPrev={() => setStep(Steps.PACKING_SLIP)}
              />
            )}
            {step === Steps.SELECT_RATE && (
              <SelectCarrier
                onPrev={() => setStep(Steps.PARCEL)}
                onNext={() => setStep(Steps.CONFIRM)}
              />
            )}
            {step === Steps.CONFIRM && (
              <PackageDetail />
            )}
          </div>
          <div style={{ flex: 1, width: '50%' }}>
            <Container
              header={<Header>Preview</Header>}
              fitHeight
            >
              {step === Steps.CONFIRM ? (
                <PDFViewer />
              ) : (
                <PDFPreview />
              )}
            </Container>
          </div>
        </div>
      </ContentLayout>
    </PackageProvider>
  );
}
