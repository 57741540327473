import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { formatDate } from '@parallel-fluidics/shipping';
import { useInfiniteQuery } from '@tanstack/react-query';
import { httpsCallable } from 'firebase/functions';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFunctions } from 'reactfire';

import { useNotifications } from '../../features/notifications';
import { formatCurrency } from '../../utils';
import AddPOModal from './addPOModal';

const StatusMap = Object.freeze({
  accepted: 'Accepted',
  open: 'Open',
  canceled: 'Canceled',
  draft: 'Draft',
});
const StatusColorMap = Object.freeze({
  accepted: 'green',
  open: 'blue',
  canceled: 'severity-high',
  draft: 'grey',
});

export default function StripeQuotes() {
  const functions = useFunctions();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [statusFilter, setStatusFilter] = useState(null);
  const [downloadingQuotes, setDownloadingQuotes] = useState(new Set());
  const [quoteToCreate, setQuoteToCreate] = useState(null);

  const {
    data: { pages = [], pageParams = [] } = {},
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['getStripeQuotes', statusFilter],
    queryFn: async ({ pageParam }) => {
      const getStripeQuoteList = httpsCallable(functions, 'listQuotes');
      const { data } = await getStripeQuoteList({
        startingAfter: pageParam,
        statusFilter,
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.has_more) return null;
      return lastPage.data?.[lastPage.data.length - 1]?.id;
    },
    refetchOnWindowFocus: false,
  });

  const handleDownloadPDF = useCallback(async (quoteId, quoteNumber) => {
    try {
      setDownloadingQuotes((prev) => new Set(prev).add(quoteId));
      const getQuotePDF = httpsCallable(functions, 'getQuotePDF');
      const response = await getQuotePDF({ quoteId });
      // Decode base64 PDF
      const { pdfBase64 } = response.data;
      const blob = new Blob([Uint8Array.from(atob(pdfBase64), (c) => c.charCodeAt(0))], {
        type: 'application/pdf',
      });
      // Create a downloadable link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Quote-${quoteNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      addNotification({
        type: 'error',
        dismissible: true,
        content: `Error downloading PDF: ${error.message}`,
      });
    } finally {
      setDownloadingQuotes((prev) => {
        const newSet = new Set(prev);
        newSet.delete(quoteId);
        return newSet;
      });
    }
  }, [addNotification, functions]);

  const quotesList = useMemo(() => (pages[currentPageIndex - 1]?.data || []).map((quote) => ({
    ...quote,
    amount: (
      <div style={{ display: 'flex', justifyContent: 'end', gap: 8 }}>
        <Box color={quote.status === 'canceled' ? 'text-status-inactive' : ''}>
          {formatCurrency(quote.amount_total / 100)}
        </Box>
        <Box color="text-status-inactive">{quote.currency?.toUpperCase()}</Box>
      </div>
    ),
    statusBadge: (
      <Badge color={StatusColorMap[quote.status]}>
        {StatusMap[quote.status]}
      </Badge>
    ),
    expiration: formatDate(quote.expires_at * 1000),
    createdOn: new Date(quote.created * 1000).toLocaleString(),
    actions: (
      <ButtonDropdown
        items={[
          { id: 'download', text: 'Download Quote PDF' },
          (quote.metadata?.orderId
            ? { id: 'editOrder', text: 'Edit Order' }
            : { id: 'createOrder', text: 'Create an Order', disabled: quote.status !== 'open' }
          ),
        ]}
        onItemClick={({ detail }) => {
          switch (detail.id) {
            case 'download':
              handleDownloadPDF(quote.id, quote.number);
              break;
            case 'createOrder':
              setQuoteToCreate(quote);
              break;
            case 'editOrder':
              navigate(`/orders/update/${quote.metadata.orderId}`);
              break;
            default:
          }
        }}
        expandToViewport
        loading={downloadingQuotes.has(quote.id)}
        variant="icon"
      />
    ),
  })), [currentPageIndex, downloadingQuotes, handleDownloadPDF, navigate, pages]);

  const handleStatusFilter = useCallback((status) => {
    setStatusFilter(status);
    setCurrentPageIndex(1);
  }, []);

  return (
    <>
      <Table
        header={(
          <Header
            variant="awsui-h1-sticky"
            actions={(
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant={!statusFilter ? 'primary' : 'normal'} onClick={() => handleStatusFilter(null)}>All</Button>
                {Object.keys(StatusMap).map((status) => (
                  <Button
                    key={status}
                    variant={statusFilter === status ? 'primary' : 'normal'}
                    onClick={() => handleStatusFilter(status)}
                  >
                    {StatusMap[status]}
                  </Button>
                ))}
              </SpaceBetween>
          )}
          >
            Stripe Quotes
          </Header>
      )}
        empty={(
          <Box
            color="inherit"
            fontWeight="bold"
          >
            No quotes found
          </Box>
      )}
        variant="full-page"
        stickyHeader
        loading={isFetching}
        items={quotesList}
        resizableColumns
        pagination={(
          <Pagination
            currentPageIndex={currentPageIndex}
            onChange={({ detail }) => {
            // only fetchNextPage for new page
              if (detail.currentPageIndex > pageParams.length) {
                fetchNextPage();
              }
              setCurrentPageIndex(detail.currentPageIndex);
            }}
            openEnd={hasNextPage}
            pagesCount={pageParams.length}
          />
      )}
        columnDefinitions={[
          {
            header: 'Amount',
            cell: (item) => item.amount,
            width: 140,
          },
          {
            header: 'Status',
            cell: (item) => item.statusBadge,
          },
          {
            header: 'Quote Number',
            cell: (item) => item.number,
          },
          {
            header: 'Customer',
            cell: (item) => item.customer?.email,
          },
          {
            header: 'Expiration',
            cell: (item) => item.expiration,
          },
          {
            header: 'Created',
            cell: (item) => item.createdOn,
          },
          {
            header: 'Action',
            cell: (item) => item.actions,
          },
        ]}
      />
      {quoteToCreate && (
        <AddPOModal
          quote={quoteToCreate}
          open={Boolean(quoteToCreate)}
          onClose={() => setQuoteToCreate(null)}
        />
      )}
    </>
  );
}
