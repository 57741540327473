const { calculate: calculateV3 } = require('./v3');
const { calculateUnitPriceV0 } = require('./v0');

module.exports.calculateUnitPrice = ({ quote, options }) => {
  switch (quote.pricingVersion) {
    case 'v3':
      return calculateV3({ quote, options });
    default: {
      // use the original pricing formula
      const { rates, leadtimeMultiples } = quote;
      const {
        material, cappingStyle, numConnectors, quantity, delivery,
      } = options;
      return calculateUnitPriceV0(
        rates,
        material,
        cappingStyle,
        numConnectors,
        quantity,
        delivery,
        leadtimeMultiples,
      );
    }
  }
};
